export const GET_MAIN_SLIDER = "GET_MAIN_SLIDER"; 
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_MAIN_FILTER_CATEGORY_CURRENT = "GET_MAIN_FILTER_CATEGORY_CURRENT";
export const GET_MAIN_SLIDER_RANDOM = "GET_MAIN_SLIDER_RANDOM";
export const GET_FILM_DETAIL = "GET_FILM_DETAIL";
export const GET_CATEGORY_CURRENT = "GET_CATEGORY_CURRENT";
export const GET_CATEGORY_CURRENT_FILTER = "GET_CATEGORY_CURRENT_FILTER";
export const GET_SEARCH_FILMS = "GET_SEARCH_FILMS";
export const GET_CATEGORY_CURRENT_LABELS_FILTER = "GET_CATEGORY_CURRENT_LABELS_FILTER";

export const CHANGE_EL_CHECKBOX_CURRENT_FILTER = "CHANGE_EL_CHECKBOX_CURRENT_FILTER";
export const RESET_MAIN_FILTER_CATEGORY_CURRENT = "RESET_MAIN_FILTER_CATEGORY_CURRENT";
